import React from "react";
import { Link } from "react-router-dom";

export const Privacy = () => {
  return (
    <div id="privacy" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>OutFitMe AI Privacy and Terms of Use Agreement</h2>
          <p>Last Updated: March 8, 2025</p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="privacy-text">
              <h3>1. Introduction and Definitions</h3>
              <h4>1.1 Scope</h4>
              <p>
                This Agreement governs your use of the OutFitMe AI mobile application ("App") and the services provided therein ("Services"). By using the App, you ("User" or "Subscriber") agree to be bound by these terms, including our policies on data collection, processing, and subscription billing.
              </p>
              
              <h4>1.2 Definitions</h4>
              <p>
                <strong>User/Subscriber:</strong> Any individual or legal entity registered with OutFitMe AI and accessing the Services.<br />
                <strong>Services:</strong> All functions provided within the App, including photo uploading, image processing, and AI-driven virtual try-on functionalities.<br />
                <strong>Subscription Package:</strong> Service plans available on a weekly ($2.99), monthly ($4.99), and annual ($44.99) basis.<br />
                <strong>Automatic Renewal:</strong> The process by which the subscription fee is charged to the User's account at the end of each billing cycle unless canceled by the User.<br />
                <strong>Billing Period:</strong> The duration for which a subscription package is valid, corresponding to the chosen plan.
              </p>

              <h3>2. Data Collection and Privacy</h3>
              <h4>2.1 Information Collected</h4>
              <p>
                OutFitMe AI collects various types of information necessary to provide and improve our Services, including:
              </p>
              <ul>
                <li>Personal Information: Such as name, email address, and other details provided during registration.</li>
                <li>Media and Photo Data: Images captured or selected by the User, including both clothing and portrait photos used in the virtual try-on process.</li>
                <li>Device Information: Data regarding the device type, operating system, IP address, and other technical details.</li>
                <li>Usage Data: Interaction logs, error reports, performance metrics, and other analytics information.</li>
              </ul>

              <h4>2.2 Use of Information</h4>
              <p>
                Collected information is used to:
              </p>
              <ul>
                <li>Operate and enhance the functionality of the App, including AI-driven image processing.</li>
                <li>Improve User experience through data analysis and performance optimization.</li>
                <li>Comply with legal obligations and respond to lawful requests.</li>
                <li>Provide support and communicate important updates to the User.</li>
              </ul>

              <h4>2.3 Data Security</h4>
              <p>
                We implement industry-standard encryption and security measures to protect your data. However, no transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
              </p>

              <h4>2.4 Third-Party Services</h4>
              <p>
                Our App integrates third-party libraries and payment processors (e.g., react-native-vision-camera, image-crop-picker, and official payment platforms). Each third-party service operates under its own privacy policy. Users are encouraged to review these policies for additional details.
              </p>

              <h4>2.5 User Rights</h4>
              <p>
                Users may request access to, correction of, or deletion of their personal information. Such requests can be directed to our support team at support@outfitmeai.com.
              </p>

              <h4>2.6 Cookies and Tracking Technologies</h4>
              <p>
                We may use cookies or similar tracking technologies within the App to optimize service performance. Additional details and management options are provided within the App's settings and help sections.
              </p>

              <h3>3. Subscription Services, Payment, and Billing</h3>
              <h4>3.1 Subscription Packages and Pricing</h4>
              <p>
                OutFitMe AI offers the following subscription plans:
              </p>
              <ul>
                <li>Weekly Subscription: $4.99 per 7-day billing period.</li>
                <li>Annual Subscription: $59.99 per 365-day billing period.</li>
              </ul>
              <p>
                Prices are quoted in United States Dollars (USD) and include any applicable taxes or fees as indicated at the point of purchase.
              </p>

              <h4>3.2 Payment Methods and Billing</h4>
              <p>
                Payments are processed through official platforms such as the App Store, Google Play, or other authorized payment gateways. By subscribing, you authorize the respective platform to charge your account at the beginning of each billing period. If a trial period is offered, billing will commence upon its conclusion, unless the subscription is canceled before the trial expires.
              </p>

              <h4>3.3 Automatic Renewal and Cancellation</h4>
              <p>
                <strong>Automatic Renewal:</strong> Subscriptions automatically renew at the end of each billing cycle.<br />
                <strong>Cancellation:</strong> Users can cancel the automatic renewal via their respective App Store or subscription management settings. Cancellation will remain effective until the end of the current billing period, and no refunds will be issued for partial periods.
              </p>

              <h4>3.4 Refund Policy</h4>
              <p>
                Refunds for subscription fees are subject to the policies of the payment provider and the App Store. If the Service is significantly defective or not delivered as described, Users may contact our support team for further resolution.
              </p>

              <h3>4. Terms of Use and User Obligations</h3>
              <h4>4.1 Acceptable Use</h4>
              <p>
                The App and Services are provided solely for personal and lawful use. Users agree not to:
              </p>
              <ul>
                <li>Misuse or abuse the App or its functionalities.</li>
                <li>Copy, modify, or redistribute content without proper authorization.</li>
                <li>Engage in any conduct that may damage, disable, or impair the functionality of the App.</li>
              </ul>

              <h4>4.2 Accuracy of Information</h4>
              <p>
                Users must provide accurate, complete, and up-to-date information during registration and subsequent interactions. Failure to do so may result in restricted access or termination of Services.
              </p>

              <h4>4.3 Intellectual Property</h4>
              <p>
                All intellectual property rights in the App and its content are owned by OutFitMe AI or its licensors. Unauthorized reproduction, distribution, or commercial exploitation of the content is prohibited.
              </p>

              <h4>4.4 User Conduct</h4>
              <p>
                Users are expected to use the Services in a manner that does not harm other Users or interfere with the operation of the App. Violations of these terms may result in suspension or termination of your subscription and access to the App.
              </p>

              <h3>5. Disclaimer and Limitation of Liability</h3>
              <h4>5.1 Service Disclaimer</h4>
              <p>
                OutFitMe AI strives to deliver high-quality services but does not guarantee that the App will be error-free, uninterrupted, or meet all Users' expectations. Services are provided "as is" without warranties of any kind, either express or implied.
              </p>

              <h4>5.2 Limitation of Liability</h4>
              <p>
                To the fullest extent permitted by law, OutFitMe AI shall not be liable for:
              </p>
              <ul>
                <li>Direct, indirect, incidental, or consequential damages arising from the use or inability to use the App.</li>
                <li>Loss of data, revenue, or profit, or any other economic loss.</li>
                <li>Any actions taken in reliance on the information provided within the App.</li>
              </ul>

              <h4>5.3 Force Majeure</h4>
              <p>
                OutFitMe AI is not liable for any failure to perform its obligations if such failure is due to events beyond its control, including natural disasters, war, terrorism, labor disputes, technical failures, or other circumstances deemed as force majeure.
              </p>

              <h3>6. Changes, Notifications, and Legal Provisions</h3>
              <h4>6.1 Modifications to the Agreement</h4>
              <p>
                OutFitMe AI reserves the right to modify this Agreement, including changes to the Services, subscription fees, or policies. Users will be notified at least 30 days in advance of any material changes via the App or through email.
              </p>

              <h4>6.2 Governing Law and Jurisdiction</h4>
              <p>
                This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which OutFitMe AI operates. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the designated local courts or arbitration centers.
              </p>

              <h4>6.3 Entire Agreement</h4>
              <p>
                This document, along with any policies referenced herein, constitutes the entire agreement between the User and OutFitMe AI and supersedes any prior communications or agreements regarding the subject matter.
              </p>

              <h4>6.4 Severability</h4>
              <p>
                If any provision of this Agreement is found to be unenforceable or invalid, the remaining provisions will continue in full force and effect.
              </p>

              <h3>7. Contact and Support</h3>
              <p>
                For any questions regarding this Agreement, subscriptions, billing issues, or technical support, please contact us at:
              </p>
              <p>
                Support Email: info@luintech.com<br />
                Website: luintech.com
              </p>
              
              <div className="back-to-home">
                <Link to="/" className="btn btn-custom btn-lg">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 